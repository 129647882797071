import { firebaseDb } from '../firebase/config'
import { collection, addDoc, updateDoc, deleteDoc, query, where, limit, doc, getDoc, getDocs, startAt, orderBy } from 'firebase/firestore'

class currency {
    constructor(id, code, description, fixedValue, isCrypto, uid) {
        this.id = id;
        this.code = code;
        this.description = description;
        this.fixedValue = fixedValue;
        this.isCrypto = isCrypto;
        this.uid = uid
    }

    toString() {
        return this.description;
    }
}

const currencyConverter = {
    toFirestore: (currency) => {
        return {
            code: currency.code,
            description: currency.description,
            fixedValue: currency.fixedValue,
            isCrypto: currency.isCrypto,
            uid: currency.uid
        };
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        return new currency(snapshot.id, data.code, data.description, data.fixedValue, data.isCrypto, data.uid);
    },
};

async function getCurrency(uid, code) {
    const firebaseCollection = collection(firebaseDb, 'currency');

    const searchQuery = query(firebaseCollection, where('uid', '==', uid), where('code', '==', code), limit(1)).withConverter(currencyConverter);
    const searchSnapshot = await getDocs(searchQuery);

    if (searchSnapshot.docs.length > 0) {
        return searchSnapshot.docs[0].data();
    }
    else {
        return null;
    }
};

async function updateCurrency(id, code, description, fixedValue, isCrypto, uid) {
    const firebaseCollection = collection(firebaseDb, 'currency');

    const searchQuery = query(firebaseCollection, where('uid', '==', uid), where('code', '==', code), limit(1)).withConverter(currencyConverter);
    const searchSnapshot = await getDocs(searchQuery);

    if (searchSnapshot.docs.length > 0) {
        const existing = searchSnapshot.docs[0];
        await updateDoc(existing.ref, {
            code: code,
            description: description,
            fixedValue: fixedValue,
            isCrypto: isCrypto,
            uid: uid
        });
    }
};

async function createCurrency(code, description, fixedValue, isCrypto, uid) {
    const firebaseCollection = collection(firebaseDb, 'currency');

    const existing = await getCurrency(uid, code);
    if (!existing) {
        var newItem = {
            uid: uid,
            code: code,
            description: description,
            isCrypto: isCrypto,
            fixedValue: fixedValue
        }
        const userRef = await addDoc(firebaseCollection, newItem);

        return userRef;
    }
    else {
        await updateCurrency(existing.id, code, description, fixedValue, isCrypto, uid)
        return existing;
    }
};

async function searchCurrency(uid, searchText, pageIndex, pageSize) {
    const firebaseCollection = collection(firebaseDb, 'currency');
    const recordIndex = (pageSize * pageIndex) + 1
    const searchQuery = query(firebaseCollection, where('uid', '==', uid), orderBy("code"), startAt(recordIndex), limit(pageSize)).withConverter(currencyConverter);
    const searchSnapshot = await getDocs(searchQuery);
    return searchSnapshot.docs.map(doc => doc.data());
}

async function deleteCurrency(id) {
    console.log(`delete id: ${id}`);
    await deleteDoc(doc(firebaseDb, 'currency', id));
}

export {
    getCurrency,
    updateCurrency,
    createCurrency,
    searchCurrency,
    deleteCurrency
};
