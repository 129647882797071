<template>
    <v-container id="timeline" fluid tag="section">
        <v-snackbar v-model="showAlert" :timeout="5000" right top elevation="4" color="red darken-2">
            {{ alertText }}
        </v-snackbar>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title>
                        Currency
                    </v-card-title>
                    <v-card-subtitle>

                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <div class="d-flex justify-space-between flex-wrap">
                            <v-btn class="ma-2" dark color="primary" :loading="loading" @click="showDialog = true">
                                <v-icon>mdi-plus</v-icon>
                                Add Currency
                            </v-btn>
                        </div>

                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" hide-details
                            :disabled="loading">
                        </v-text-field>
                    </v-card-subtitle>
                    <v-card-text>
                        <v-data-table :search="search" :headers="headers" :items="items" :items-per-page="8"
                            :loading="loading" item-key="id" class="elevation-1 table-one">
                            <template v-slot:item.id="{ item }">
                                <div class="d-flex align-center">
                                    <p class="ma-0 font-weight-medium">
                                        {{ item.id }}
                                    </p>
                                </div>
                            </template>
                            <template v-slot:item.isCrypto="{ item }">
                                <v-simple-checkbox disabled v-model="item.isCrypto" />
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <v-icon title="edit item" color="success" @click="editItemClick(item)">
                                    mdi-pencil-box-outline
                                </v-icon>
                                <v-icon title="delete item" color="danger" @click="deleteItemClick(item)">
                                    mdi-trash-can-outline
                                </v-icon>
                            </template>
                        </v-data-table>
                    </v-card-text>

                    <v-dialog v-model="showDialogDelete" max-width="500px">
                        <v-card>
                            <v-card-title>Are you sure you want to delete this item?</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn variant="text" @click="deleteCloseClick" :loading="loading">Cancel</v-btn>
                                <v-btn color="primary" variant="text" @click="deleteConfirmClick"
                                    :loading="loading">OK</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <v-dialog v-model="showDialog" max-width="500px">
                        <v-card>
                            <v-card-title>
                                <span class="text-h5">{{ formTitle }}</span>
                            </v-card-title>

                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" sm="6" md="10">
                                            <v-text-field v-model="editedItem.code" label="Code"
                                                :rules="[rules.required]"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="10">
                                            <v-text-field v-model="editedItem.description"
                                                label="Description"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="6">
                                            <v-checkbox v-model="editedItem.isCrypto" label="Is Crypto?"></v-checkbox>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="10">
                                            <v-text-field v-model="editedItem.fixedValue" label="Fixed Value" prefix="$"
                                                :disabled="editedItem.isCrypto"></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn variant="text" @click="closeClick" :loading="loading">
                                    Cancel
                                </v-btn>
                                <v-btn :disabled="!canSave" color="primary" variant="text" @click="saveClick"
                                    :loading="loading">
                                    Save
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import { getSessionObject } from "../../../session/sessionData";
import { searchCurrency, updateCurrency, createCurrency, deleteCurrency } from '../../../data/currencyData';

export default {
    data() {
        return {
            user: null,
            search: '',
            loading: false,
            headers: [
                {
                    text: 'Code',
                    align: 'start',
                    value: 'code',
                    sortable: false
                },
                { text: 'Description', sortable: false, value: 'description' },
                { text: 'Is Crypto?', sortable: false, value: 'isCrypto' },
                { text: 'Fixed Value', sortable: false, value: 'fixedValue' },
                { text: 'Actions', sortable: false, value: 'actions' }
            ],
            items: [],
            showAlert: false,
            alertText: '',
            showDialog: false,
            showDialogDelete: false,
            editedIndex: -1,
            editedItem: {
                code: '',
                description: '',
                isCrypto: false,
                fixedValue: '',
            },
            defaultItem: {
                code: '',
                description: '',
                isCrypto: false,
                fixedValue: '',
            },
            originalCode: '',
            rules: {
                required: value => !!value || 'Required.'
            }
        }
    },
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Currency'
    },
    created: function () {
        this.user = getSessionObject('user');
        this.loadItems('', 1, 10);
    },
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'New Currency' : 'Edit Currency'
        },
        canSave() {
            return this.editedItem.code > ''
        }
    },
    watch: {
        showDialog(val) {
            val || this.closeClick()
        },
        showDialogDelete(val) {
            val || this.deleteCloseClick()
        },
    },
    methods: {
        async loadItems(searchText, pageIndex, pageSize) {
            this.loading = true;
            this.items = await searchCurrency(this.user.uid, searchText, pageIndex, pageSize)
            this.loading = false;
        },
        editItemClick(item) {
            this.editedIndex = this.items.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.originalCode = this.editedItem.code;
            this.showDialog = true
        },

        deleteItemClick(item) {
            this.editedIndex = this.items.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.showDialogDelete = true
        },
        deleteCloseClick() {
            this.showDialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        deleteConfirmClick() {
            this.loading = true;

            deleteCurrency(this.editedItem.id)
                .then((response) => {
                    this.items.splice(this.editedIndex, 1)
                    this.deleteCloseClick();
                    this.loading = false;
                })
                .catch((error) => {
                    this.alertText = error
                    this.showAlert = true;
                    this.deleteCloseClick()
                    this.loading = false;
                });
        },
        closeClick() {
            this.showDialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        saveClick() {
            if (this.editedIndex > -1) {
                this.updateRecord();
            } else {
                this.addRecord();
            }
        },
        addRecord() {
            this.loading = true;

            createCurrency(this.editedItem.code, this.editedItem.description, this.editedItem.fixedValue, this.editedItem.isCrypto, this.user.uid)
                .then((response) => {

                    this.items.push(this.editedItem)
                    this.closeClick();
                    this.loading = false;
                })
                .catch((error) => {
                    this.alertText = error
                    this.showAlert = true;
                    this.closeClick()
                    this.loading = false;
                });
        },
        updateRecord() {
            this.loading = true;
            //TODO add data call here
            this.closeClick();
            this.loading = false;
        },
    }
}
</script>
<style lang="scss" scoped>
::v-deep .table-one {
    thead.v-data-table-header {
        tr {
            th {
                span {
                    font-size: 16px;
                    color: #304156;
                }
            }
        }

        tr {
            td {
                padding-bottom: 20px;
                padding-top: 20px;
            }
        }
    }
}
</style>
